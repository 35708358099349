import { Injectable, inject } from "@angular/core";
import { BaseService } from "./base.service";
import { HttpRestService } from "./http-client/http-rest-client.service";

@Injectable()
export class QuizService extends BaseService {
    private restService = inject(HttpRestService);

    constructor() {
        super();
    }

    public async sendQuizResultEmail(data: any): Promise<boolean> {
        const result = await this.handleResponse(this.restService.post({
            url: `/quiz/send-quiz-result-email`,
            data: data
        }));

        return result;
    }
}
